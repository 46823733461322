@import '../../styles/variables.module.scss';

$shadow: transparentize(#000, 0.8);

.button {
  height: 40px;
  padding: 0 32px;
  border-radius: 8px;
  background: $white;
  color: $primary;
  font-family: $barlow-semi-condensed;
  font-weight: 500;
  font-size: 1.8rem;
  text-transform: uppercase;


  &:hover:not([disabled]) {
    box-shadow: 1px 2px 2px $shadow;
    cursor: pointer;
  }

  &:active:not([disabled]) {
    box-shadow: 1px 2px 2px $gray-light inset;
  }

  &:disabled {
    background-color: $gray-light;
    cursor: unset;
  }
}

.primary {
  @extend .button;
  background: $primary;
  color: $text-light;
  border: none;

  &:hover:not([disabled]) {
    background: $primary;
    color: $secondary-light;
    box-shadow: 1px 2px 2px $gray-light;
  }

  &:active:not([disabled]) {
    box-shadow: 1px 2px 2px $gray-dark inset;
  }
}
.fullWidth {
  @extend .button;
  width: 100%
}
.secondary {
  @extend .button;
  border: 2px solid $primary;

  &:hover {
    border-color: $primary-dark;
    color: $primary-dark;
  }
}

.warning {
  @extend .primary;
  background: $warning-red;
  &:hover:not([disabled]) {
    background: $white;
    color: $text-red;
    border: 2px solid $warning-red;
  }
}
