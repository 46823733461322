@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 100;
  src: url('./Barlow-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 200;
  src: url('./Barlow-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 300;
  src: url('./Barlow-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url('./Barlow-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  src: url('./Barlow-Regular-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  src: url('./Barlow-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  src: url('./Barlow-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: url('./Barlow-Bold.ttf') format('truetype');
}
