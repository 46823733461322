@import './fonts/index.scss';
@import './styles/variables.module.scss';

html {
  font-size: 62.5%;
}

h1 {
  font-family: $barlow-condensed;
  font-weight: $weight-thin;
  font-size: 4.8rem;
  margin: 0;
}

h2 {
  font-family: $barlow;
  font-weight: $weight-semi-bold;
  font-size: 2.4rem;
  margin: 0;
}

h3 {
  font-family: $barlow-condensed;
  font-weight: $weight-regular;
  font-size: 2.4rem;
  margin: 0;
}

body {
  font-family: $barlow;
  font-weight: $weight-regular;
  font-size: 1.6rem;
  margin: 0;
}
