// COLOR PALLET
$primary-ultralight: #cdf1f1;
$primary-light: #98dede;
$primary: #424242;
$primary-dark: #1c4f4f;

$secondary-light: #a9cbf4;
$secondary: #4c94e8;
$secondary-dark: #30639e;

$tertiary-light: #ccc4fc;
$tertiary: #9383f9;
$tertiary-dark: #6256b2;

$trafficlight-green: #b7e7c2;
$trafficlight-yellow: #f4e2a4;
$trafficlight-red: #f4a9a4;

// TONES
$white: #fff;
$gray-light: #d1d8d8;
$gray-dark: #565d5d;
$black: #000;


// TEXT COLOR
$text-green: #49b260;
$text-yellow: #ebb909;
$text-red: #eb584f;
$text-dark: #051515;
$text-light: #fff;

// BACKGROUND COLOR
$background-green: #b7e7c2;
$background-yellow: #f4e2a4;
$background-red: #f4a9a4;

$warning-red: #ed5e68;

:export {
  primaryLight: $primary-light;
  primary: $primary;
  primaryDark: $primary-dark;

  secondaryLight: $secondary-light;
  secondary: $secondary;
  secondaryDark: $secondary-dark;

  tertiaryLight: $tertiary-light;
  tertiary: $tertiary;
  tertiaryDark: $tertiary-dark;

  trafficlightGreen: $trafficlight-green;
  trafficlightYellow: $trafficlight-yellow;
  trafficlightRed: $trafficlight-red;

  toneWhite: $white;
  toneGrayLight: $gray-light;
  toneGrayDark: $gray-dark;
  toneBlack: $black;

  textGreen: $text-green;
  textYellow: $text-yellow;
  textRed: $text-red;

  backgroundGreen: $background-green;
  backgroundYellow: $background-yellow;
  backgroundRed: $background-red;

  warningRed: $warning-red;
}

// FONTS
$barlow: 'Barlow', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$barlow-condensed: 'Barlow Condensed', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$barlow-semi-condensed: 'Barlow Semi Condensed', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

// FONT WEIGHTS
$weight-thin: 100;
$weight-extra-light: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semi-bold: 600;
$weight-bold: 700;
