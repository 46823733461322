@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 100;
  src: url('./BarlowCondensed-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 200;
  src: url('./BarlowCondensed-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('./BarlowCondensed-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('./BarlowCondensed-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 500;
  src: url('./BarlowCondensed-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 600;
  src: url('./BarlowCondensed-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('./BarlowCondensed-Bold.ttf') format('truetype');
}
